import { reportErrorLevel } from '@ucm-it/purrfect-reporting';
import { isDevelopment } from '@ucm-it/purrfect-utilities';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useCurrentSession } from '../src/contexts';
import { useCustomRouter } from './useCustomRouter';
import { useDebugMode } from './useDebugMode';

export function useAutoRedirects() {
	const { hasNoValidApplications, isReady, selectedApp } = useCurrentSession();
	const { renderURL } = useDebugMode();
	const [targetPage, setTargetPage] = useState<string>('');

	const { pathname, query } = useCustomRouter();
	const router = useRouter();

	useEffect(() => {
		if (!isReady) {
			return;
		}

		if (isDevelopment() && pathname === 'debug') {
			setTargetPage('debug');
		} else if (selectedApp !== null) {
			const target = renderURL(selectedApp.page);
			setTargetPage(target.pathname + target.search);

			return;
		} else if (hasNoValidApplications) {
			setTargetPage('noApplications');
		} else {
			setTargetPage('selectTerm');
		}
	}, [isReady, pathname, selectedApp]);

	useEffect(() => {
		if (
			query.DEBUG === 'true' ||
			targetPage === '' ||
			pathname === targetPage
		) {
			return;
		}

		router.push(targetPage).catch(reportErrorLevel);
	}, [targetPage, pathname]);
}
